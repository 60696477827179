@media screen and (min-width: 540px) {
     .phase-form-container .header-container {
        flex-direction: column;
    }

    .action-buttons {
        align-self: flex-end;
        text-align: -webkit-right;
    }


}


@media screen and (min-width: 768px) {

     .phase-form-container .header-container {
        flex-direction: initial;
    }
}


@media screen and (min-width: 1280px) {


}






