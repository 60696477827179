.slider-horizontal.not_touch input{
    background-color: #ADADAD;
}

.slider-horizontal .rangeslider__fill {
    background-color: #1e88e5 !important;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    top: 50% !important;
    transform: translate3d(-50%,-50%,0) !important;
}
.slider-horizontal .rangeslider__handle {
    width: 10px!important;
    height: 30px!important;
}
.rangeslider .rangeslider__handle {
    background: #fff!important;
    border: 1px solid #ccc!important;
    cursor: pointer!important;
    display: inline-block!important;
    position: absolute!important;
    box-shadow: none!important;
}

.rangeslider-horizontal .rangeslider__handle:after{
    width:0 !important;
    height: 0!important;
}