.animation ~  div  > *{

    animation: fadeLeft 0.3s 0.5s linear both;
}

@keyframes fadeLeft {
    from{
        opacity: 0;
    transform: translateX(-100%);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}