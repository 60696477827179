// Colors
$brand-primary : var(--brand-primary-color);
$brand-secondary : var(--brand-secondary-color);

$brand-tertiary : var(--gray-1-color);
$brand-tertiary-light : var(--gray-6-color);
$brand-tertiary-light : var(--gray-6-color);
$brand-tertiary-9 : var(--gray-9-color);





//layout
$layout-md-max-width: var(--scaffold-layout-md-max-width);







// Font
$font-size-base: 14px;
$font-family: 'CircularStd', sans-serif;

// Colors
$white-color: #fff;
$white-inverse: #4a4a4a;

$gray: #f6f7fb;

$dark-color: #111;
$dark-inverse: $white-color;

$primary-color: #0E4B78;
$primary-color: #2581c4;

$secondary-color: var(--secondary-color);
$secondary-inverse: $white-color;

$danger-color: #F44336;;
$danger-inverse: $white-color;

$danger-secondary-color: #FEF2EC;
$danger-secondary-color: #83171A;
$danger-inverse: $white-color;

$success-color: #6bb230;
$success-inverse: $white-color;

$light-color: $white-color;
$light-inverse: #333;

$text-color: #4a4a4a;
$link-color: var(--secondary-color);

$text-light-color: #8f9194;

// Social colors
$facebook-color: #3a589b;
$facebook-inverse: $white-color;

$whatsapp-color: #09d261;
$whatsapp-inverse: $white-color;

$instagram-color: radial-gradient(
  circle at 1.83% 90.83%,
  #fed576 0,
  #f47133 26%,
  #bc3081 61%,
  #4c63d2 100%
);
$instagram-inverse: $white-color;

$linkedin-color: #0084bf;
$linkedin-inverse: $white-color;

$twitter-color: #5189cb;
$twitter-inverse: $white-color;

$youtube-color: #cc181e;
$youtube-inverse: $white-color;

$publishing-color: var(--secondary-color);
$publishing-inverse: $secondary-inverse;

$kaltura-color: #1b4a97;

// Input colors
$input-bg: $white-color;
$input-border-color: #edeff4;

// Badge color
$badge-color: #ff8f08;
$badge-background: $white-color;

// Restrict header
$header-height: 74px;
$restrict-header-color: $text-color;
$restrict-header-background: #fff;

// Restrict menu
$menu-width: 220px;

// Grid
$break-min: 850px;
$break-max: $break-min - 1px;

// Z-indexes
$z-index-badge-count: 1200;
$z-index-message: 1001;
$z-index-loading: 750;
$z-index-more-screen: 900;
$z-index-header: 1000;
$z-index-modal: 2000;

// Others
$separator-color: #dde0e6;
$login-bg: #f6f7fb;
$more-bg: #f6f7fb;

$indicator-color-high: var(--indicator-color-high);
    //--indicator-color-mid: #FFA940;
    //--indicator-color-hight: #73D13D;
    //--indicator-color-default: #000;