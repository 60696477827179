.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container .input-alert {
    color: indianred;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    line-height: 16px;

}

.input-container label {
    color: #949494;
    font-size: 18px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    background: inherit;
}

.input-container input {
    background-color: #f8f8f8;

    padding: 14px 16px;
    border-radius: 8px;
    border: unset;
    outline: unset;


    font-size: 1em;
    line-height: 24px;
    color: #646464;
    height: 100%;
}