.program-filter-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.program-filter-container .input-container{
    width: 90%;
    align-self: center;
}