.titleAllocation {
    /*box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 4px;*/
    flex-grow: 1;
    font-size: 22px;
    font-weight: 500;
    /*padding: 16px 10px 8px 16px;*/
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    position:sticky;
    top:0;
    z-index:2;
    background-color:white;
}

.linkAllocation{
    color:var(--active_menu_color);
}