.ant-notification-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
}

.ant-notification-notice-close {
    position: absolute;
    right: 10px !important;
    top: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
}

.ant-notification-notice-description {
    margin: 0px !important;
}

.ant-notification-notice-message {
    padding: 0px !important;
}

.ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
}