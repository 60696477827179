.program-list-evaluations .program-container{
    /*max-width: 1280px;*/
}
.actionBarProgramList {
    padding: 0px !important;
    margin: 0px !important;
    height: 95px;
    align-items: center;
    width: 100%;

}

.divCard {
    max-width: 202px !important;
    min-width: 202px;
    height: 208px;
    background-color: #F8F8F8;
    margin: 8px;
    margin-bottom: 16px;
    border-radius: 8px;
}

.rowCards {
    padding: 0px !important;
    margin: 0px !important;
    max-height: 70vh;

}

.itemDesc {
    font-size: 12px;
    color: #646464;
    text-align: initial;
    padding-right: 5px;
    padding-left: 5px;
    max-height:50%;
    overflow:auto
}.itemDesc::-webkit-scrollbar {
    width: 0px
}

.rowImgPerfil {
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px !important;
    width: 100% !important;
    margin: 0px !important;
    justify-content: flex-end;
    margin-bottom:7px !important;
    margin-right:1px !important;
}

.imgPerfil {
    padding: 0px !important;
    width: 32px !important;
    margin-top: 3px !important;
    margin-right: 8px !important;
}

.itemNome {
    color: #4C4C4C;
    font-size: 12px;
    font-weight: 700
}

.imgAlarm {
    padding: 0px !important;
    width: 12px !important;
    margin-right: 3px !important
}

.programaFormGroup {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 100% !important;
    background-color: #F8F8F8 !important;
    border: none !important;
    border-radius: 8px !important;
}

.ProgramaInputSearch {
    background-color: #F8F8F8 !important;
    border: none !important;
    border-radius: 8px !important;
    height: 32px !important;
    padding-top: 2px !important;
    opacity: 0.7 !important
}

.createButtonPattern {
    background-color: #DBF0FF;
    border-radius: 6px;
    height: 36px;
    border: none;
 padding:0px 40px;
 border:1px solid #DBF0FF;
 color: #2581C4;
}.createButtonPattern:hover{
    color: #0E4B78;
}

.rowSpinner {
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
}

.card-program-row{
    padding-bottom: 14px;
    width: 100%;
}

.hide-effect{
    display: none;
}