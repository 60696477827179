.card-phase-container {
    position: relative;
    padding-bottom: 22px;
    padding-right: 10px;
    width: 100%;

}

* .card-phase-container:last-child::after {
    content: '';
    border: none;
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;

}

* .card-phase-container:first-child .card-content-action-buttons a:last-child {
    visibility: hidden;
}

.card-phase-container::after {
    content: '';
    border-left: 2px dashed #2581C4;
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;

}


/*CARD HEADER*/

.card-phase-container .card-header-container {
    display: flex;
    color: #2581C4;
    align-items: center;

}

.card-phase-container .card-header-container .card-index {
    background-color: #DBF0FF;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    text-align: center;
    line-height: 30px;
    z-index: 1;

}

.card-phase-container .card-header-container .card-title {
    font-size: 1em;
    margin: 0 0 0 8px;
    /* text-transform: capitalize */

}


/*CARD CONTENT*/

.card-phase-container .card-content-container {
    margin: 8px 0 0 40px;
    padding: 8px;
    box-shadow: 0px 0px 8px rgba(221, 221, 221, 0.61);
    border-radius: 8px;
    position: relative;
    min-height: 50px;
    border: 1px solid #f8f8f8;
    background-color: white;
}

.card-phase-container .card-content-container:hover {
    border-color: #ddd;
}

.listIcon {
    margin-bottom: 2.3px;
    margin-right: 8px;
}

.typeCardDiv {
    background: rgba(221, 221, 221, 0.46);
    border-radius: 4px;
    width: 30%
}

.card-content-container .card-content-label {
    line-height: 1em;
    font-weight: 700;
    font-size: 0.75em;
    font-style: normal;
    color: #949494;
}

.card-content-label-type {
    font-weight: 400;
    font-size: 12px;
    color: #646464;
    background: rgba(221, 221, 221, 0.46);
    border-radius: 4px;
    width: 380px;
    padding: 2px 8px;
}

.card-content-container .card-content-description {
    margin-top: 8px;
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    color: #646464;
    width: 786px;

}



.card-content-container .card-content-action-buttons a {
    display: block;
    width: 26px;
    height: 26px;
    text-align: center;
}

.card-content-container .card-content-action-buttons a:nth-child(2) {
    margin-left: 2px;
}


.card-content-container .card-content-action-buttons a:hover {
    background-color: #DDDDDD;
    cursor: pointer;
    border-radius: 5px;
}

.card-content-container .card-content-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
}





.card-content-container .card-content-info .phase-warning {
    width: 200px;
    display: block;
    background-color: #FFFCE8;
    padding: 0 4px;
    color: #CCB400;
    border-radius: 4px;
    text-align: -webkit-center;
}

.phase-warning:hover {
    text-decoration: none;
    cursor: pointer;
}

.card-content-container .card-content-info .phase-warning span {
    margin-left: 8px;
    line-height: 1em;
    font-size: 0.75em;
    font-weight: 400;
    font-style: normal;
}

.date_end {
    margin-left: 10px;
}


.card-action-container {
    display: flex;
    justify-content: flex-end;

}

.card-action-button {
    margin-top: 8px;
    width: 150px;
    height: 32px;
    visibility: hidden;

}

.card-phase-container:hover .card-action-button,
.card-phase-container:hover .card-content-action-buttons {
    visibility: visible;
    transition: .5s
}


.card-custom .modal-footer {
    padding: 6.75rem !important;
    justify-content: center !important;
    font-size: 16px;
}

.card-custom .modal-title {
    font-size: 16px !important;
    color: darkgray !important;
}

.EvaEntreButton {
    color: var(--primary_btn_color) !important;
    background-color: white !important;
    font-size: 16px;
    border: 1px solid #fff !important;
    padding: 15px 20px !important;
    margin: 30px 0px;
    border-radius: 4px
}

.EvaEntreButton:hover {
    border: 1px solid var(--primary_btn_color) !important;
    text-decoration: none !important
}

.cardLinkEdit2:hover {
    cursor: pointer;
    text-decoration: none
}

.cardLinkEdit:hover {
    cursor: pointer;
    text-decoration: none
}

.card-content-label-app {
    font-size: 12px;
    line-height: 16px;
    color: #646464;
    padding:2px 6px;
    border-radius:4px
}

.card-content-container .card-content-action-buttons {
    margin-top: 4px;
    border-radius: 4px;
    display: flex;
    position: absolute;
    top: 4px;
    right: 10px;
    /* background-color:#F8F8F8; */
    /* visibility: hidden; */
    flex-direction: column;
    justify-content: flex-end;
}

.addPhasePatern {
    border-radius: 20px !important;
    background-color: #DBF0FF !important;
    color: #2581C4 !important;
    border: none !important;

}

.addPhasePatern:hover {
    background-color: #DBF0FF !important;
    color: #2581C4 !important;
}