.icon_button-container{
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    opacity:1;
}

.icon_button-container:hover{
    background-color: #DDDDDD !important

}

.icon_button-container:disabled{
    opacity:.4;
    background-color: transparent !important
}