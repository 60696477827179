@keyframes hiddenApplication {
    from{
        transform: translateX(0);
        opacity: 1;
    }
    to{
        transform: translateX(100%);
        opacity: 0;
    }

}

.mainDiv {
    height: 100%;
    width: 100%;
}

.divPhase {
    display: flex;
    justify-content: space-around;
}

.phaseCard {
    width: 250px;
    height: 70px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    -webkit-transform: skew(-8deg);
    flex-direction: column;
    justify-content: space-evenly;
}

.phaseNome {
    font-size: 17px;
    font-weight: 500
}

.phaseApply {
    font-size: 14px;
    color: #6c757d;
    font-weight: 500
}

.applyList {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px */
}

.actionBarApply {
    width: 100%;
    display: flex;
    /*height: 32px;*/
    box-shadow: none;
}

.div3Apply {
    flex: 5;
    text-align: start;
    align-self: center;
    font-weight: 400;
    height: 100%;
    color: #4C4C4C;

}

.divButtonApply {
    flex: 5;
    justify-content: center;
    text-align: center;
}

.buttonAprove {
    width: 90%;
    background-color: white;
    border: 1px solid #51bb25;
    font-size: 14px;
    font-weight: 500;
    color: #51bb25;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    height: 100%
}

.buttonAprove:hover {
    background-color: #51bb25;
    color: white
}

.buttonReprove {
    width: 90%;
    background-color: white;
    border: 1px solid #dc3545;
    font-size: 14px;
    font-weight: 500;
    color: #dc3545;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    height: 100%
}

.buttonReprove:hover {
    background-color: #dc3545;
    color: white
}

.applyListCards {
    width: 100%;
    /*padding: 5px;*/
    overflow: hidden;
}

.divApplyCard {
    display: flex;
    height: 110px;
    margin: 8px 0;
    border-radius: 8px;
    background-color: #F8F8F8;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;

}

.name-startup:hover {
    cursor: pointer;
    color: #2581C4!important;
}

.divApplyCard:hover .divMiniButtons {
    opacity: 1
}

.miniButtonAprove {
    background-color: #DEF7E4;
    color: #28A745;
    width: 100px;
    padding: 4px 0;
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    margin-right: 8px
}

.miniButtonAprove:hover {
    background-color: #DEF7E4;
    color: #28A745;
    border: 1px solid;
}

.miniButtonReprove {
    margin-right: 8px;
    background-color: #FEF2EC;
    color: #F44336;
    width: 100px;
    padding: 4px 0px;
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    margin-left: 8px
}

.miniButtonReprove:hover {
    background-color: #FEF2EC;
    color: #F44336;
    border: 1px solid;
}

.divMiniButtons {
    margin-top: 12px;
    opacity: 0
}

/*.pendingSubmitedText {*/
/*    font-size: 14px;*/
/*    color: #F4D400;*/
/*    background-color: #F8F8F8;*/
/*    text-align: center;*/
/*    border-radius: 8px;*/
/*    padding: 0px 5px;*/
/*    height: 23px;*/
/*    border: 1px solid #F4D400;*/
/*    margin-right: 8px;*/
/*    margin-top: 0px*/
/*}*/

/*.submitedText {*/
/*    font-size: 14px;*/
/*    color: #51bb25;*/
/*    background-color: #F8F8F8;*/
/*    text-align: center;*/
/*    border-radius: 8px;*/
/*    padding: 0px 5px;*/
/*    height: 23px;*/
/*    border: 1px solid #51bb25;*/
/*    margin-right: 8px;*/
/*    margin-top: 0px*/
/*}*/

/*.noSubmitedText {*/
/*    background-color: #F8F8F8;*/
/*    border: 1px solid #dc3545;*/
/*    font-size: 14px;*/
/*    color: #dc3545;*/
/*    text-align: center;*/
/*    border-radius: 8px;*/
/*    padding: 0px 5px;*/
/*    height: 23px;*/
/*    margin-right: 8px*/
/*}*/

.title-form {
    box-sizing: border-box;
    /*width: 100%;*/
    height: 38px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-style: normal;
    font-size: 2em;
    line-height: 38px;
    color: #4C4C4C;
    margin-top: 32px;
    margin-bottom: 3px
}

.applyListCards .approved {
    border: 0;
    /* border-left-width: 10px;
    border-color: #51bb25; */
    border-style: solid;
}

.applyListCards .reproved {
    border: 0;
    /* border-left-width: 10px;
    border-color: #dc3545; */
    border-style: solid;
}

.hidebutton {
    visibility: hidden !important;
}

.button-finish-all {
    background-color: #2581c4;
    /*width: 100%;*/
    padding: 4px 8px;
    border-radius: 4px;
    color: #DBF0FF;
    border: none;
    opacity: 1;

}

.button-finish-all:hover {
    background-color: #0E4B78;
}


.statusQtdSpan {
    color: #ADADAD;
    padding: 0px 15px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ADADAD;
    height: 100%;
    display: flex;
    align-items: center;
}

.btn-status-application {
    display: flex;

}

.btn-status-application button {
    /*width: 120px;*/
    justify-content: center;
}

.btn-status-application button:hover {
    cursor: pointer !important;
    border: 1px solid #2581C4;
    color: #2581C4;
}

.btn-status-application button.active {
    cursor: pointer !important;
    border: solid;
    border-color: #2581C4;
    color: #2581C4;
}