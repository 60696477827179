.App {
    text-align: center;
}

html {
    font-size: 100%;
}

/* * {
    letter-spacing: .5px !important;
} */

*::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;

}

*::-webkit-scrollbar-thumb {
    background-color: #949494 !important;
    border-radius: 20px !important;

}

*::-webkit-scrollbar-thumb:hover {
    background-color: #747373 !important
}

.hidden-menu {
    animation: 0.5s hiddenMenu linear both;
}

@keyframes hiddenMenu {
    0% {
        transform: translateX(0);
        opacity: 1;
        display: flex;
    }

    100% {
        transform: translateX(-50%);
        opacity: 0;
        display: none !important;
    }

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@keyframes appear {
    from {
        margin-right: 400px
    }

    top {
        margin-right: 0px;
    }
}

.menuAnimation {
    animation-duration: 0.5s;
    animation-name: appear;
    min-width: 200px;
    z-index: 2
}

.menuAnimation:hover {
    text-decoration: none;
}

@media screen and (min-width: 540px) {
    .divMenuMain {
        position: fixed;
        z-index: 2;
        /*left: 0px;*/
        /*margin-top: 13px*/
    }

    .divLayoutContent {
        padding-left: 10px;
    }

    .backDrop {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1
    }
}

@media screen and (min-width: 768px) {
    .divMenuMain {
        position: fixed;
        z-index: 2;
        /*left: 10px;*/
        /*margin-top: 13px*/
    }

    .divLayoutContent {
        padding-left: 16px;
    }

    .backDrop {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1
    }
}

@media screen and (min-width: 1200px) {
    .divMenuMain {
        position: relative;
        /*left: 10px;*/
        margin-top: 0px
    }

    .divLayoutContent {
        padding-left: 16px;
    }

    .backDrop {
        width: 0%;
        height: 0%;
        z-index: 1
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

:root {
    --brand-primary-color: #2581C4;
    --brand-secondary-color: #dbf0ff;
    --brand-tertiary-color: rgb(4, 44, 78);

    --gray-1-color: #FFFFFF;
    --gray-5-color: #ADADAD;
    --gray-6-color: #c0c0c0;
    --gray-9-color: #333333;
    --gray-10-color: rgba(0,0,0,0.45);

    overflow: overlay;
    background-color: #f8f8f8;
    --scaffold-layout-gutter: 1.2rem;
    --scaffold-layout-none-max-width: 576px;
    --scaffold-layout-md-max-width: 720px;
    --scaffold-layout-lg-max-width: 960px;
    --scaffold-layout-xl-max-width: 1128px;

    --background_action_bar: none;
    --text_color_action_bar: none;
    --background_menu: #ffffff;
    --text_color_menu: none;
    --active_menu_color: none;
    --action_color: none;
    --primary_btn_color: none;
    --primary_btn_text_color: none;

    --login_background_cover: none;
    --login_text_color_cover: none;
    --login_background_button: none;
    --login_text_color_button: none;

    --indicator-color-low: #FF4D4F;
    --indicator-color-mid: #FFA940;
    --indicator-color-high: #73D13D;
    --indicator-color-default: #000;


}

/*input:-webkit-autofill,*/
/*input:-webkit-autofill:hover,*/
/*select:-webkit-autofill:focus {*/
/*  !*border: 1px solid green;*!*/
/*  -webkit-text-fill-color: var(--login_text_color_cover);*/
/*  -webkit-box-shadow: 0 0 0px 1000px var(--login_background_cover) inset;*/
/*  transition: background-color 5000s ease-in-out 0s;*/
/*}*/


.divLayoutContent {
    width: 100%;
    padding: 0px;
    margin: 0px;
    max-width: 1280px;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "➝" !important;
    /*color: #6E6E6E !important;*/
    color: #6E6E6E !important;
    font-weight: 500 !important;

}

@media screen and (max-width: 900px) {
    .breadcrumb-item + .breadcrumb-item::before {
        content: none !important;
        /*color: #6E6E6E !important;*/
        color: #ADADAD !important;
        font-weight: 500 !important;
    }
}

.breadcrumb-item {
    font-size: 12px;
    text-transform: capitalize;
    /*color: #2581C4 !important;*/
    color: var(--active_menu_color) !important;
}

.breadcrumb-item a {
    color: #6E6E6E !important;
}

.breadcrumb-item {


}

.breadcrumb_program {
    margin-top: 10px;
    margin-bottom: -10px;
}

.nav-item span {
    text-transform: capitalize !important;
}

a {
    text-decoration: none !important;
}

.underline-link {
    text-decoration: underline !important;   
}

.react-confirm-alert-button-group > button:not(:first-child) {
    background-color: var(--primary_btn_color) !important;
    color: var(--primary_btn_text_color) !important;

}
.react-confirm-alert-button-group > button:first-child {
    background-color: #c6c6c6 !important;
    color: white !important;
}

@media print {
    html body { overflow-y: unset !important; }
}