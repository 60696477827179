.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    margin-top:20px;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  
  .cropDiv{
    position: absolute;
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    width:50%;
    height:50%;
    z-index:100;
    /* background-color:#F8F8F8; */
    background-color:#909090;
  }

  .divModalImageCrop{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center
  }.inputModalImageCrop:hover{
    cursor:pointer !important
  }

  .inputModalImageCrop{
    display:flex;
    position:absolute;
    opacity:0;
    width:100%;
    height:100%;
    margin-top:25px
  }

  .input-modal-img {
    /* width: 100% !important; */
  }