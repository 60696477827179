@import 'src/variables';

.button {
  text-align: center;

  &.primary {
    background-color: $brand-primary;
    color: $brand-secondary;
    border-radius: 6px;
    height: 36px;
    padding: 5px 20px;
    border: 1px solid $brand-secondary;
    width: fit-content;

    &:hover {
      outline: none;
      text-decoration-line: none;
      border: 1px solid $brand-primary;
      background-color: $brand-primary;
    }
  }

  &.secondary {
    background-color: $brand-secondary;
    color: $brand-primary;
    border-radius: 6px;
    height: 36px;
    padding: 5px 20px;
    border: 1px solid $brand-secondary;
    width: fit-content;

    &:hover {
      outline: none;
      text-decoration-line: none;
      border: 1px solid $brand-primary;
      background-color: $brand-secondary;
    }

  }

  &.neutral {
     border-radius: 6px;
     height: 36px;
     padding: 5px 20px;
     width: fit-content;
  }

  &.tertiary {
    background-color: $brand-tertiary;
    color: $brand-tertiary-light;
    border-radius: 6px;
    height: 36px;
    padding: 5px 20px;
    border: 1px solid $brand-tertiary-light;
    width: fit-content;

    &:hover {
      outline: none;
      text-decoration-line: none;
      border: 1px solid $brand-tertiary-light;
      background-color: $brand-tertiary;
      color: $brand-tertiary-9;
    }
  }

  &.danger {
    background-color: $danger-color;
    color: $danger-inverse;
    border-radius: 6px;
    height: 36px;
    padding: 5px 20px;
    border: 1px solid;
    width: fit-content;

    &:hover {
      outline: none;
      text-decoration-line: none;
      border: 1px solid $danger-color;
      background-color: $danger-color;
    }
  }

  &.buttonBaseApprove {
    background-color: #28A745;
    color: white;
    width: 100px;
    padding: 0px;
    height: 30px;
    margin:0;
    font-size: 13px;
  }

  &.buttonBaseApprove:hover {
    background-color: #28A745;
    color: white;
    border: 1px solid #DEF7E4;
  }

  &.buttonBaseExport {
    padding: 2px 4px;
    width: 100px;
    background-color: rgba(196, 196, 196, 0.3);
    color: #6E6E6E;
    border: 2px solid transparent;
  }

  &.buttonBaseExport:hover {
    background-color: rgba(196, 196, 196, 0.3);
    color: #6E6E6E;
    border: 2px solid #DDDDDD;
  }

  &.buttonBaseReprove {
    background-color: #C6C6C6;
    color: white;
    width: 100px;
    padding: 0px;
    height: 30px;
    margin:0;
    font-size: 13px;
  }

  &.buttonBaseReprove:hover {
    background-color: #C6C6C6;
    color: white;
    border: 1px solid #FEF2EC;
  }
}