@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
    margin: 0;
    padding: 0;
}

:root{
    --font-default-color: rgba(0, 0, 0, 0.85);
    --font-secondary-color: rgba(0, 0, 0, 0.45);
    --font-fading-color: rgba(0, 0, 0, 0.06);
    --solv-fading-background: #fafafa;
    --solv-fading-background-secondary: #f5f5f5;

}
body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    font-family: 'Roboto', sans-serif !important;
}

#root:has(#prevent-horizontal-scrolling) {
    overflow-x: hidden;
}

/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
