@import 'src/variables';

.button {
  display: inline-block;
  padding: 5px 50px;
  margin: 10px 0;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 100px;
  transition: background 200ms;
  white-space: nowrap;
  &.fill {
    width: 100%;
    // padding: 15px 50px;
  }

  &.small {
    padding: 6px 15px;
    font-size: 0.8rem;
  }

  &.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    padding: 0;
    font-size: 1.5rem;

    &.small {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1rem;
    }
  }

  &.black {
    color: $dark-color;

    &:hover {
      color: lighten($dark-color, 10%);
    }
  }

  &.primary {
    background: var(--primary-color);

    &:hover {
      // background: lighten(var(--primary-color), 10%);
    }
  }

  &.secondary {
    background: $secondary-color;


    &:hover {
      //background: lighten(var(--secondary-color), 10%);
    }
  }

  &.success {
    background: var(--success-color);

    &:hover {
      // background: lighten(var(--success-color), 10%);
    }
  }

  &.danger {
    background: $danger-color;

    &:hover {
      background: lighten($danger-color, 10%);
    }
  }

  &.dark {
    background: $dark-color;

    &:hover {
      background: lighten($dark-color, 10%);
    }
  }

  &.facebook {
    background: $facebook-color;
  }

  &.whatsapp {
    background: $whatsapp-color;
  }

  &.instagram {
    background: $instagram-color;
  }

  &.linkedin {
    background: $linkedin-color;
  }

  &.twitter {
    background: $twitter-color;
  }

  &.youtube {
    background: $youtube-color;
  }

  &.publishing {
    background: $publishing-color;
  }

  &.kaltura {
    background: $kaltura-color;
  }

  &.link {
    padding: 0 5px;
    background: none;

    &:hover {
      background: none;
    }

    &.black {
      color: $dark-color;

      &:hover {
        color: lighten($dark-color, 10%);
      }
    }

    &.primary {
      color: var(--primary-color);

      &:hover {
        // color: lighten(var(--primary-color), 10%);
      }
    }

    &.secondary {
      color: var(--secondary-color);

      &:hover {
        // color: lighten(var(--secondary-color), 10%);
      }
    }

    &.danger {
      color: $danger-color;

      &:hover {
        color: lighten($danger-color, 10%);
      }
    }
  }

  &.outline {
    border: 2px solid;
    background: none;

    &.black {
      color: $dark-color;
      border-color: $dark-color;

      &:hover {
        color: $dark-inverse;
        background: $dark-color;
      }
    }

    &.primary {
      color: var(--primary-color);
      border-color: var(--primary-color);

      &:hover {
        color: var(--background-inverse-color);
        background: var(--primary-color);
      }
    }

    &.secondary {
      color: var(--secondary-color);
      border-color: var(--secondary-color);

      &:hover {
        color: $secondary-inverse;
        background: var(--secondary-color);
      }
    }

    &.success {
      color: var(--success-color);
      border-color: var(--success-color);

      &:hover {
        color: $success-inverse;
        background: var(--success-color);
      }
    }

    &.danger {
      color: $danger-color;
      border-color: $danger-color;

      &:hover {
        color: $danger-inverse;
        background: $danger-color;
      }
    }

    &.dark {
      color: $dark-color;
      border-color: $dark-color;

      &:hover {
        color: $dark-inverse;
        background: $dark-color;
      }
    }

    &.facebook {
      color: $facebook-color;
      border-color: $facebook-color;

      &:hover {
        color: $facebook-inverse;
        background: $facebook-color;
      }
    }

    &.whatsapp {
      color: $whatsapp-color;
      border-color: $whatsapp-color;

      &:hover {
        color: $whatsapp-inverse;
        background: $whatsapp-color;
      }
    }

    &.instagram {
      color: $instagram-color;
      border-color: $instagram-color;

      &:hover {
        color: $instagram-inverse;
        background: $instagram-color;
      }
    }

    &.linkedin {
      color: $linkedin-color;
      border-color: $linkedin-color;

      &:hover {
        color: $linkedin-inverse;
        background: $linkedin-color;
      }
    }

    &.twitter {
      color: $twitter-color;
      border-color: $twitter-color;

      &:hover {
        color: $twitter-inverse;
        background: $twitter-color;
      }
    }

    &.youtube {
      color: $youtube-color;
      border-color: $youtube-color;

      &:hover {
        color: $youtube-inverse;
        background: $youtube-color;
      }
    }

    &.publishing {
      color: $publishing-color;
      border-color: $publishing-color;

      &:hover {
        color: $publishing-inverse;
        background: $publishing-color;
      }
    }
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }
}

.mobile-version {
  .button {
    &, &.fill {
      padding: 5px 20px;
    }

    &.circle {
      padding: 0;
    }
  }
}