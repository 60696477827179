.phase-form-container {
    margin-top: 8px;
}

.phase-form-container .header-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 44px;
}

.action-buttons > *:nth-child(1){
    margin-left: 0;
}




.action-buttons > *{
    margin-left: 38px;

}

.action-buttons .cancel-button{
    padding: 4px 14px;
    color: #646464;
    font-weight: normal;
    font-style: normal;
    font-size: 0.875em;
    line-height: 20px;
    background-color: transparent;
    border-radius: 4px;
    border: unset;
}

.action-buttons .cancel-button:hover{
    background-color: #f8f8f8;
}

.action-buttons .save-button{
    background-color: #2581C4;
    color: #DBF0FF;
    border: unset;
    border-radius: 4px;
    padding: 4px 14px;

    font-size: 0.875em;
    line-height: 20px;
    font-weight: 400;
}

.action-buttons .save-button:hover{
    background-color: #042C4E;
}

.phase-form-container .title-form {
    box-sizing: border-box;
    /*width: 100%;*/
    height: 38px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-style: normal;
    font-size: 2em;
    line-height: 38px;
    color: #4C4C4C;
}

.form-container{
    max-width: 880px;
}

.form-container .input-divide-row{
    margin-top: 16px;
}

.copy-program-container{
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px;
    display: flex;
}

.copy-program-container .copy-phase-container button{
    border-radius: 8px;
    border: solid 1px #DDDDDD;
    width: 150px;
    padding: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
     color: #646464;
    margin-right: 16px;
}

.copy-program-container .copy-phase-container button:hover{
    background-color: #f8f8f8;
}
